<template>
  <div class="relative">
    <div class="p-2 shadow flex justify-between items-center bg-send-grey-nav">
      <Logo />

      <div v-if="loggedIn" class="flex gap-2 items-center">
        <FwbAvatar
          rounded
          status-position="bottom-right"
          status="online"
          class="rounded-full border border-black"
          :img="avatar ?? undefined"
          :initials="displayName?.substring(0, 1) ?? 'A'"
        />
        <LogoutButton />
      </div>
    </div>

    <div
      class="relative w-full min-h-screen h-screen overflow-y-auto flex flex-col bg-send-grey-bg"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import FwbAvatar from "~/components/Flowbite/FwbAvatar/FwbAvatar.vue";
import LogoutButton from "~/components/auth/LogoutButton.vue";
import Logo from "~/components/partials/logo.vue";

const userStore = useUserStore();
const authStore = useAuthStore();
const { loggedIn } = toRefs(authStore);
const { displayName, avatar } = toRefs(userStore);
</script>
